import { Injectable, Compiler } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { AppSettings } from '../../settings.global';
import { ActivatedRoute, Router } from '@angular/router';
//import { Observable } from 'rxjs';
import { Subject, Observable, Observer } from 'rxjs'
import { map, catchError } from 'rxjs/operators';
import * as socketIo from 'socket.io-client';

import { Socket } from '../../interfaces/shared/socket';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var io: {
    connect(url: string): Socket;
};

@Injectable()
export class AuthService {
    public headers: HttpHeaders;
    public myheaders: HttpHeaders;
    public deviceid: any;
    public push_token: any;
    public device_type: any;
    public login_type: any;
    public type: any;
    private subject = new Subject<any>();
    private subject2 = new Subject<any>();

    socket: Socket;
    observer: Observer<any>;

    getQuotes(): Observable<any> {
        this.socket = socketIo('https://screenshot.insiderscorp.com', {
            withCredentials: true
        });

        // this.socket = socketIo('http://localhost:3000');
        this.socket.on('data', (res) => {
            this.observer.next(res.data);
        });

        return this.createObservable();
    }
    createObservable(): Observable<number> {
        return new Observable(observer => {
            this.observer = observer;
        });
    }

    sendMessage(message: any) {
        console.log(message, "ppppppp")
        this.subject.next({ message });
    }
    clearMessage() {
        this.subject.next();
    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    // sendUrlCount(message: any) {
    //     console.log(message,"ppppppp")
    //     this.subject2.next({message});
    // }
    // clearUrlCount() {
    //     this.subject2.next();
    // }
    // getUrlCount(): Observable<any> {
    //     return this.subject2.asObservable();
    // }
    constructor(public httpClient: HttpClient, public localSt: LocalStorageService, public router: Router, public _compiler: Compiler) {
        //The http object and the header is set
        this.headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept': 'application/json',
        });
        //Devise details
        this.deviceid = "mydevice";
        this.push_token = "demo";
        this.device_type = "web";
        this.type = "admin";
    }

    /*
     * authenticate user   
     */
    authenticate(body: any) {
        console.log('body', body);
        this.myheaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        });

        let bodyString: string = "email=" + body.email + "&password=" + body.password + "&device_type=" + this.device_type + "&device_id=" + this.deviceid + "&push_token=" + this.push_token + "&type=" + this.type
        /*
         * Requst for login
         */
        return this.httpClient.post(AppSettings.API_ENDPOINT + '/auth/login', bodyString, { headers: this.myheaders }).pipe(
            map(function (res: any) {
                console.log('res', res);

                return res || { status: "failed", message: "Somthing went wrong" };
            }));

    }
    //Forgot password
    forgotPassword(body: any) {
        let bodyString: string = "email=" + body.email + '&type=' + this.type;
        let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
        return this.httpClient.post(AppSettings.API_ENDPOINT + '/auth/forgot-password', bodyString, options).pipe(
            map(function (res: any) {
                return res.body || { status: "failed", message: "Somthing went wrong" };
            }));
    }
    //Change password
    changePassword(body: any) {
        let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
        let bodyString: string = "cur_password=" + body.cur_password + "&new_password=" + body.new_password + "&id=" + body.id;


        return this.httpClient.post(AppSettings.API_ENDPOINT + '/auth/change-password', bodyString, options).pipe(
            map(function (res: any) {
                return res.body || { status: "faild", message: "Somthing went wrong" };
            }));

    }
    //Logout
    logout() {
        this.localSt.clear('access_token');
        this.localSt.clear('user_details');
        this.localSt.store("login_after_logout", true);
        this.router.navigate(['/login']);
        return true;
    }
}
