import { Component, OnInit, TemplateRef, Renderer } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NgxPaginationModule } from 'ngx-pagination';//importing ng2-pagination
import { AuthService } from '../../services/auth/auth.service';
import { LoaderService } from '../../services/loader/loader.service';
import { BrandService } from '../../services/brand/brand.service';
import * as $ from 'jquery';
declare var $: any;
import { AppSettings } from '../../settings.global';
import * as _ from 'lodash';
import { DataSearchService } from '../../services/data-search/data-search.service';
import { Observable } from 'rxjs/';
import * as XLSX from 'xlsx';
// import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DatePipe } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
import { ToastrService } from 'ngx-toastr';
const swal: SweetAlert = _swal as any;

@Component({
  selector: 'app-data-search',
  templateUrl: './data-search.component.html',
  styleUrls: ['./data-search.component.css'],
  providers: [DatePipe]
})
export class DataSearchComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  public clients: any;
  public client: any;
  public clientName: any;
  public barndName: any;
  public sourceName: any;
  public brands: any;
  public brand: any;
  public searchQuery: any;
  public queryLimit: number = 100000;
  public listData: any;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public pager: any;
  public itemPerPageCount: number;
  public numberOfPages: any;
  public start: any;
  public end: any;
  public headers: any = [];
  public no_of_entry: any;
  public searchInputObservable$: any;
  public screenShots: any;
  public activeIndex: any;
  public img_url = AppSettings.IMAGE_BASE_URL;
  public selectAllFlag: boolean;
  public urlToDelete: any;
  public selectFalg: boolean;
  public checkBox: any;
  public test: any;
  public colspan: any;
  public screenshotFile: any;
  public market_place_header = [{ "old_key": "screen_shots", "new_key": "Screenshots" },
  { "old_key": "url", "new_key": "Url" },
  { "old_key": "detection_date", "new_key": "Detection Date" },
  { "old_key": "client_name", "new_key": "Client" },
  { "old_key": "brand_name", "new_key": "Brand" },
  { "old_key": "product_name", "new_key": "Product Name" },
  { "old_key": "keyword", "new_key": "Keyword" },
  { "old_key": "product_category", "new_key": "Product Category" },
  { "old_key": "mother_company", "new_key": "Parent Company" },
  { "old_key": "market_place", "new_key": "Market Place" },
  { "old_key": "country", "new_key": "Country" },
  { "old_key": "price", "new_key": "Price" },
  { "old_key": "currency", "new_key": "Currency" },
  { "old_key": "item_number", "new_key": "Item No." },
  { "old_key": "status", "new_key": "Status" },
  { "old_key": "in_stock", "new_key": "In Stock" },
  { "old_key": "type_of_infringement", "new_key": "Type Of Infringement" },
  { "old_key": "seller_id", "new_key": "Seller ID" },
  { "old_key": "seller_name", "new_key": "Seller Name" },
  { "old_key": "phone_number_1", "new_key": "Phone No. #1" },
  { "old_key": "phone_number_2", "new_key": "Phone No. #2" },
  { "old_key": "email_address_1", "new_key": "Email Address #1" },
  { "old_key": "email_address_2", "new_key": "Email Address #2" },
  { "old_key": "address", "new_key": "Address" },
  { "old_key": "social_network_link", "new_key": "Social Network Link" },
  { "old_key": "marketplace_link", "new_key": "Marketplace Link" },
  { "old_key": "standalone_website_link", "new_key": "Standalone Website Link" }
  ];

  public social_media_header = [{ "old_key": "screen_shots", "new_key": "Screenshots" },
  { "old_key": "url", "new_key": "Url" },
  { "old_key": "detection_date", "new_key": "Detection Date" },
  { "old_key": "client_name", "new_key": "Client" },
  { "old_key": "brand_name", "new_key": "Brand" },
  { "old_key": "product_name", "new_key": "Product Name" },
  { "old_key": "keyword", "new_key": "Keyword" },
  { "old_key": "product_category", "new_key": "Product Category" },
  { "old_key": "mother_company", "new_key": "Parent Company" },
  { "old_key": "social_media", "new_key": "Social Media" },
  { "old_key": "country", "new_key": "Country" },
  { "old_key": "price", "new_key": "Price" },
  { "old_key": "currency", "new_key": "Currency" },
  { "old_key": "item_number", "new_key": "Item No." },
  { "old_key": "url_profile", "new_key": "URL Profile" },
  { "old_key": "status", "new_key": "Status" },
  { "old_key": "likes", "new_key": "Likes" },
  { "old_key": "in_stock", "new_key": "In Stock" },
  { "old_key": "type_of_infringement", "new_key": "Type Of Infringement" },
  { "old_key": "profile_name", "new_key": "Seller ID" },
  { "old_key": "owner_name", "new_key": "Seller Name" },
  { "old_key": "phone_number_1", "new_key": "Phone No. #1" },
  { "old_key": "phone_number_2", "new_key": "Phone No. #2" },
  { "old_key": "email_address_1", "new_key": "Email Address #1" },
  { "old_key": "email_address_2", "new_key": "Email Address #2" },
  { "old_key": "address", "new_key": "Address" },
  { "old_key": "social_network_link", "new_key": "Social Network Link" },
  { "old_key": "marketplace_link", "new_key": "Marketplace Link" },
  { "old_key": "standalone_website_link", "new_key": "Standalone Website Link" },
  { "old_key": "member_since", "new_key": "Member Since" },
  { "old_key": "location_product", "new_key": "Location Product" },
  { "old_key": "ad_publication_date", "new_key": "Ad publication Date" },
  { "old_key": "followers", "new_key": "Followers" },
  { "old_key": "subscriptions", "new_key": "Subscriptions" },
  { "old_key": "profile_type", "new_key": "Profile Type" },
  { "old_key": "living_at", "new_key": "Living At" },
  { "old_key": "location", "new_key": "Location" },
  { "old_key": "description", "new_key": "Description" },
  { "old_key": "website", "new_key": "Website" },
  { "old_key": "facebook", "new_key": "Facebook" },
  { "old_key": "instagram", "new_key": "Instagram" },
  { "old_key": "pinterest", "new_key": "Pinterest" },
  { "old_key": "tiktok", "new_key": "Tiktok" },
  { "old_key": "twitter", "new_key": "Twitter" },
  { "old_key": "youtube", "new_key": "Youtube" },
  { "old_key": "snapchat", "new_key": "Snapchat" },
  { "old_key": "telegram", "new_key": "Telegram" },
  { "old_key": "threads", "new_key": "Threads.net" },
  { "old_key": "wechat", "new_key": "Wechat" },
  { "old_key": "whatsapp", "new_key": "Whatsapp" },
  { "old_key": "short_links", "new_key": "Short Links" },
  { "old_key": "evaluation", "new_key": "Evaluation" },
  ];

  public standalone_header = [{ "old_key": "screen_shots", "new_key": "Screenshots" },
  { "old_key": "subdomain", "new_key": "Subdomain" },
  { "old_key": "detection_date", "new_key": "Notice Date" },
  { "old_key": "subdomain_date", "new_key": "Subdomain Date" },
  { "old_key": "client_name", "new_key": "Client" },
  { "old_key": "brand_name", "new_key": "Brand" },
  { "old_key": "language_area", "new_key": "Language Area" },
  { "old_key": "market", "new_key": "Market" },
  { "old_key": "search_engine", "new_key": "Search Engine" },
  { "old_key": "host", "new_key": "Host" },
  { "old_key": "host_email", "new_key": "Host Email" },
  { "old_key": "country", "new_key": "Country" },
  { "old_key": "status", "new_key": "Status" },
  { "old_key": "notices_sent", "new_key": "Notices Sent" },
  { "old_key": "ip_changed", "new_key": "IP Changed" },
  { "old_key": "registrar", "new_key": "Seller ID" },
  { "old_key": "owner_name", "new_key": "Seller Name" },
  { "old_key": "phone_number_1", "new_key": "Phone No. #1" },
  { "old_key": "phone_number_2", "new_key": "Phone No. #2" },
  { "old_key": "email_address_1", "new_key": "Email Address #1" },
  { "old_key": "email_address_2", "new_key": "Email Address #2" },
  { "old_key": "address", "new_key": "Address" },
  { "old_key": "social_network_link", "new_key": "Social Network Link" },
  { "old_key": "marketplace_link", "new_key": "Marketplace Link" },
  { "old_key": "standalone_website_link", "new_key": "Standalone Website Link" }

  ];

  public pressed: boolean = false;
  public startX: any = null;
  public startWidth: any = null;
  public indexes: any = [];
  public user_role:number = this.localSt.retrieve("user_details").user_role;
  public is_delete_available: boolean = false;

  

  constructor(private clientService: ClientService,
    public localSt: LocalStorageService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private authServiceObj: AuthService,
    private toastr: ToastrService,
    private brandService: BrandService,
    private dataSearchService: DataSearchService,
    public datepipe: DatePipe,
    private modalService: BsModalService,
    private renderer: Renderer) {
    this.client = '';
    this.sourceName = 'Market_place';
    this.searchQuery = {
      user_id: this.localSt.retrieve("user_details").id,
      source: 'm_commerce',
      client: null,
      brand: null,
      market_place: null,
      social_media: null,
      reportedLink: true,
      fromDate: null,
      toDate: null,
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.listData = [];
    this.no_of_entry = 50;
    this.pager = {
      limit: this.no_of_entry,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.itemPerPageCount=this.no_of_entry;

    if (this.searchQuery.source == 'market_place' || this.searchQuery.source == 'm_commerce' || this.searchQuery.source == 'e_commerce') {
      this.headers = this.market_place_header;
    } else if (this.searchQuery.source == 'social_media') {
      this.headers = this.social_media_header;
    } else if (this.searchQuery.source == 'stand_alone') {
      this.headers = this.standalone_header;
    }
    this.colspan = this.headers.length;
    this.screenShots = [];
    this.activeIndex = 0;
    this.selectAllFlag = false;
    this.selectFalg = false;
    this.urlToDelete = [];
    this.checkBox = [];
    this.test = [];
    this.screenshotFile = '';
    if(this.user_role==1)
    {
      this.is_delete_available=true;
    }
  }

  ngOnInit() {
  }
  ngAfterContentInit() {
    // const searchInputObj = document.querySelector('#search_text');
    // this.searchInputObservable$ = Observable.fromEvent(searchInputObj, 'keyup');

    // this.searchInputObservable$.debounceTime(100)
    //   .subscribe((event: any) => {
    //     this.onInputChange(event.target.value);
    //   });


    $("body").height("100%");
    $("html").height("100%");


    this.getClient();
    this.getSearchData(this.searchQuery, 1);
  }
  //get the client 
  getClient() {
    this.clientService.getClientSelect().subscribe((res: any) => {
      if (res.status == 'success') {
        this.clients = res.data_found;
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  getBrand(newValue) {
    this.brands = [];
    this.listData = [];
    this.loaderService.display(true);
    this.brandService.getBrandSelect(newValue).subscribe((res: any) => {
      this.loaderService.display(false);
      if (res.status == 'success') {
        this.brands = res.data_found;
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }
  // onClientChange(clientID: number) {
  //   //this.report.client=clientID;
  //   //this.reportUpload.client=clientID;
  //   this.listData = [];
  //   this.selectAllFlag = false;
  //   this.selectFalg = false;
  //   this.urlToDelete = [];
  //   this.checkBox = [];
  //   this.test = [];
  //   this.clients.forEach(element => {
  //     if (element.id == clientID) {
  //       this.clientName = element.name;
  //     }
  //   });
  //   console.log(clientID)
  //   console.log(this.searchQuery)
  //   this.searchQuery["orderby"] = "";
  //   this.searchQuery["order"] = "";
  //   this.searchQuery.client = clientID;
  //   this.getBrand(clientID);
  //   this.searchQuery.brand = 0;
  //   this.getSearchData(this.searchQuery, 1);

  // }
  // onBrandChange(brandID: number) {
  //   this.listData = [];
  //   this.selectAllFlag = false;
  //   this.selectFalg = false;
  //   this.urlToDelete = [];
  //   this.checkBox = [];
  //   this.test = [];
  //   this.searchQuery["orderby"] = "";
  //   this.searchQuery["order"] = "";
  //   this.searchQuery['brand'] = brandID;
  //   this.brands.forEach(element => {
  //     if (element.id == brandID) {
  //       this.barndName = element.name;
  //     }
  //   });

  //   this.getSearchData(this.searchQuery, 1)
  // }
  onSorceChange(source: any) {
    this.listData = [];
    this.selectAllFlag = false;
    this.selectFalg = false;
    this.urlToDelete = [];
    this.checkBox = [];
    this.test = [];
    this.searchQuery["orderby"] = "";
    this.searchQuery["order"] = "";
    if (source == 'market_place') {
      this.headers = this.market_place_header;
      this.sourceName = 'Market_place';
    } else if (source == 'social_media') {
      this.headers = this.social_media_header;
      this.sourceName = 'Social_media';
    } else if (source == 'stand_alone') {
      this.headers = this.standalone_header;
      this.sourceName = 'Standalone_website';
    }
    this.colspan = this.headers.length;
    this.apiCallCount = 0;
    this.offSet = 0;
    this.getSearchData(this.searchQuery, 1);
  }
  getSearchData(data, page: number) {
    this.indexes.forEach(index => {
      $('.glowTableHeader tr th:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
    });
    this.indexes=[];
    this.searchQuery['offset'] = this.offSet;
    this.loaderService.display(true);
    this.dataSearchService.getSearchData(this.searchQuery).subscribe((res: any) => {
      if (res.status == 'success' && !_.isNil(res.data) && !_.isNull(res.data)) {
        console.log("success",res)
        this.pager["total_count"] = this.listData.length + res.data.length;
        this.pager["current_page"] = page;
        const result = this.listData.concat(res.data);
        this.listData = result;
        if (!_.isNil(res.max_possible_res_length) && !_.isNull(res.data) && res.max_possible_res_length > this.listData.length + 1) {
          this.apiCallCount++;
          this.offSet = this.apiCallCount * this.queryLimit;
          this.getSearchData(this.searchQuery, 1);
        } else {
          this.loaderService.display(false);
        }
      } else {
        console.log("failed")
        this.loaderService.display(false);
        if (this.listData.length === 0 || res.max_possible_res_length === 0) {
          this.listData = [];
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages = this.pager["current_page"];
          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout();
        }
      }
    })
    this.initResizableColumns();
  }
  onSort(orderby: string, id: any) {
    this.indexes.forEach(index => {
      $('.glowTableHeader tr th:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
      $('.glowTableBody tr td:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
    });
    this.indexes=[];
    console.log(orderby, id);
    if (this.searchQuery["orderby"] === orderby) {
      if (this.searchQuery["order"] === "asc") {
        this.searchQuery["order"] = "desc";
      } else {
        this.searchQuery["order"] = "asc";
      }
    } else {
      this.searchQuery["orderby"] = orderby;
      this.searchQuery["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.listData = this.dataSearchService.sortListData(this.listData, this.searchQuery["orderby"], this.searchQuery["order"]);
    
  }
  onSearch() {
    this.listData = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.searchQuery["orderby"] = "";
    this.searchQuery["order"] = "";
    this.getSearchData(this.searchQuery, 1)
  }
  onInputChange(text) {
    this.onSearch();
  }
  entryNoChange(value: number) {
    this.listData = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    // this.dashData.limit = value;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.searchQuery["orderby"] = "";
    this.searchQuery["order"] = "";
    this.getSearchData(this.searchQuery, 1);
    this.itemPerPageCount=value;
  }
  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
    const totalItemsCount = this.pager["total_count"];
    const numberOfItemsPerPage = this.pager["limit"];
    const pages = this.pager["current_page"];
    this.numberOfPages = totalItemsCount;
    this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
    this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
    this.indexes.forEach(index => {
      $('.glowTableHeader tr th:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
    });
    this.indexes=[];
  }
  fireEvent(type: string) {
    //Copy to variable to avoid original data mutation
    const mData = this.listData;
    if (mData.length == 0) {
      return false;
    }
    console.log(type)
    //Format the data to avoid unwanted fields
    var keyMap;
    if (this.searchQuery.source == 'market_place' || this.searchQuery.source == 'm_commerce' || this.searchQuery.source == 'e_commerce') {
      keyMap = {
        detection_date: 'DetectionDate',
        client_name: 'Client',
        brand_name: 'Brand',
        product_name: 'ProductName',
        keyword: 'Keyword',
        product_category: 'ProductCategory',
        market_place: 'MarketPlace',
        country: 'Country',
        price: 'Price',
        currency: 'Currency',
        item_number: 'ItemNumber',
        url: 'URL',
        status: 'Status',
        in_stock: 'InStock',
        type_of_infringement: 'TypeOfInfringement',
        seller_id: 'SellerId',
        seller_name: 'SellerName',
        phone_number_1: 'PhoneNumber1',
        phone_number_2: 'PhoneNumber2',
        email_address_1: 'EmailAddress1',
        email_address_2: 'EmailAddress2',
        address: 'Address',
        marketplace_link: 'MarketplaceLink',
        social_network_link: 'SocialMediaLink',
        standalone_website_link: 'StandaloneWebsiteLink'
      };

    } else if (this.searchQuery.source == 'social_media') {
      keyMap = {
        detection_date: 'DetectionDate',
        client_name: 'Client',
        brand_name: 'Brand',
        product_name: 'ProductName',
        keyword: 'Keyword',
        product_category: 'ProductCategory',
        social_media: 'SocialMedia',
        country: 'Country',
        price: 'Price',
        currency: 'Currency',
        item_number: 'ItemNumber',
        url_profile: 'UrlProfile',
        url: 'URL',
        status: 'Status',
        likes: 'Likes',
        in_stock: 'InStock',
        type_of_infringement: 'TypeOfInfringement',
        seller_id: 'SellerId',
        seller_name: 'SellerName',
        phone_number_1: 'PhoneNumber1',
        phone_number_2: 'PhoneNumber2',
        email_address_1: 'EmailAddress1',
        email_address_2: 'EmailAddress2',
        address: 'Address',
        marketplace_link: 'MarketplaceLink',
        social_network_link: 'SocialMediaLink',
        standalone_website_link: 'StandaloneWebsiteLink'
      };

    } else if (this.searchQuery.source == 'stand_alone') {
      keyMap = {
        subdomain_date: 'SubdomainDate',
        detection_date: 'DetectionDate',
        client_name: 'Client',
        brand_name: 'Brand',
        language_area: 'LanguageArea',
        market: 'Market',
        search_engine: 'SearchEngine',
        subdomain: 'Subdomain',
        host: 'Host',
        host_email: 'HostEmail',
        country: 'Country',
        status: 'Status',
        notices_sent: 'NoticesSent',
        ip_changed: 'IpChanged',
        seller_id: 'SellerId',
        seller_name: 'SellerName',
        phone_number_1: 'PhoneNumber1',
        phone_number_2: 'PhoneNumber2',
        email_address_1: 'EmailAddress1',
        email_address_2: 'EmailAddress2',
        address: 'Address',
        marketplace_link: 'MarketplaceLink',
        social_network_link: 'SocialMediaLink',
        standalone_website_link: 'StandaloneWebsiteLink'
      };
    } else {
      return false;
    }

    var excelData = mData.map((obj) => {
      return _.mapKeys(obj, (value, key) => {
        return keyMap[key];
      });
    });

    const excelDataModified = excelData.map((datum, i) => {
      if (this.searchQuery.source == 'market_place' || this.searchQuery.source == 'm_commerce' || this.searchQuery.source == 'e_commerce') {
        return {
          Detection_Date: datum.DetectionDate,
          Client: datum.Client,
          Brand: datum.Brand,
          Product_Name: datum.ProductName,
          Keyword: datum.Keyword,
          Product_Category: datum.ProductCategory,
          MarketPlace: datum.MarketPlace,
          Country: datum.Country,
          Price: datum.Price,
          Currency: datum.Currency,
          Item_Number: datum.ItemNumber,
          URL: datum.URL,
          Status: datum.Status,
          In_Stock: datum.InStock,
          Type_Of_Infringement: datum.TypeOfInfringement,
          Seller_Id: datum.SellerId ? datum.SellerId : '',
          Seller_Name: datum.SellerName ? datum.SellerName : '',
          Phone_Number_1: datum.PhoneNumber1 ? datum.PhoneNumber1 : '',
          Phone_Number_2: datum.PhoneNumber2 ? datum.PhoneNumber2 : '',
          Email_Address_1: datum.EmailAddress1 ? datum.EmailAddress1 : '',
          Email_Address_2: datum.EmailAddress2 ? datum.EmailAddress2 : '',
          Address: datum.Address ? datum.Address : '',
          Marketplace_Link: datum.MarketplaceLink ? datum.MarketplaceLink : '',
          SocialMedia_Link: datum.SocialMediaLink ? datum.SocialMediaLink : '',
          Standalone_Website_Link: datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink : ''
        }
      } else if (this.searchQuery.source == 'social_media') {
        return {
          Detection_Date: datum.DetectionDate,
          Client: datum.Client,
          Brand: datum.Brand,
          Product_Name: datum.ProductName,
          Keyword: datum.Keyword,
          Product_Category: datum.ProductCategory,
          Social_Media: datum.SocialMedia,
          Country: datum.Country,
          Price: datum.Price,
          Currency: datum.Currency,
          Item_Number: datum.ItemNumber,
          Url_Profile: datum.UrlProfile,
          URL: datum.URL,
          Status: datum.Status,
          Likes: datum.Likes,
          In_Stock: datum.InStock,
          Type_Of_Infringement: datum.TypeOfInfringement,
          Seller_Id: datum.SellerId ? datum.SellerId : '',
          Seller_Name: datum.SellerName ? datum.SellerName : '',
          Phone_Number_1: datum.PhoneNumber1 ? datum.PhoneNumber1 : '',
          Phone_Number_2: datum.PhoneNumber2 ? datum.PhoneNumber2 : '',
          Email_Address_1: datum.EmailAddress1 ? datum.EmailAddress1 : '',
          Email_Address_2: datum.EmailAddress2 ? datum.EmailAddress2 : '',
          Address: datum.Address ? datum.Address : '',
          Marketplace_Link: datum.MarketplaceLink ? datum.MarketplaceLink : '',
          Social_Media_Link: datum.SocialMediaLink ? datum.SocialMediaLink : '',
          Standalone_Website_Link: datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink : ''

        }
      } else if (this.searchQuery.source == 'stand_alone') {
        return {
          Subdomain_Date: datum.SubdomainDate,
          Detection_Date: datum.DetectionDate,
          Client: datum.Client,
          Brand: datum.Brand,
          Language_Area: datum.LanguageArea,
          Market: datum.Market,
          Search_Engine: datum.SearchEngine,
          Subdomain: datum.Subdomain,
          Host: datum.Host,
          Host_Email: datum.HostEmail,
          Country: datum.Country,
          Status: datum.Status,
          Notices_Sent: datum.NoticesSent,
          Ip_Changed: datum.IpChanged,
          Seller_Id: datum.SellerId ? datum.SellerId : '',
          Seller_Name: datum.SellerName ? datum.SellerName : '',
          Phone_Number_1: datum.PhoneNumber1 ? datum.PhoneNumber1 : '',
          Phone_Number_2: datum.PhoneNumber2 ? datum.PhoneNumber2 : '',
          Email_Address_1: datum.EmailAddress1 ? datum.EmailAddress1 : '',
          Email_Address_2: datum.EmailAddress2 ? datum.EmailAddress2 : '',
          Address: datum.Address ? datum.Address : '',
          Marketplace_Link: datum.MarketplaceLink ? datum.MarketplaceLink : '',
          Social_Media_Link: datum.SocialMediaLink ? datum.SocialMediaLink : '',
          Standalone_Website_Link: datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink : ''
        }
      } else {
        return false;
      }
    });
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.myChart.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb,'Market_Place_'+this.breakdown.company+'Report.xlsx');
    if (type == 'excel') {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, this.sourceName + '_' + 'report.xlsx', { bookType: 'xlsx', type: 'buffer' });
    } else if (type == 'csv') {
      new ngxCsv(excelDataModified, this.sourceName + '_' + 'report',{ headers: Object.keys(excelDataModified[0]) });
      // new Angular2Csv(excelDataModified, this.sourceName + '_' + 'report', { headers: Object.keys(excelDataModified[0]) });
    } else {
      return false;
    }

  }
  print() {
    if (this.searchQuery.source == 'social_media') {
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>' +
          '<html>' +
          '<head>' +
          '<meta charset="utf-8" />' +
          '<title>Social-Media</title>' +
          '<style>table {border-collapse: collapse;} table, td, th { border: 1px solid black;}</style>' +
          '</head>' +
          '<body>' + '<table>' +
          '<thead>' +
          '<tr>' +
          '<th class="text-center">Detection Date </th>' +
          '<th >Client </th>' +
          '<th >Brand </th>' +
          '<th >Product Name </th>' +
          '<th >Keyword </th>' +
          '<th >Product Category </th>' +
          '<th >Social Media </th>' +
          '<th >Country </th>' +
          '<th >Price </th>' +
          '<th >Currency </th>' +
          '<th >Item Number </th>' +
          // '<th >URL Profile </th>'+
          // '<th >URL </th>'+
          '<th >Status </th>' +
          '<th >Likes </th>' +
          '<th >Item Available(in stock) </th>' +
          '<th >Type of infringement </th>' +
          '<th > Seller Id </th>' +
          '<th > Seller Name </th>' +
          '<th > Phone Number #1 </th>' +
          '<th > Phone Number #2 </th>' +
          '<th > Email Address #1 </th>' +
          '<th > Email Address #2 </th>' +
          '<th > Address </th>' +
          '</tr>' +
          '</thead>' +
          '<tbody>';
      for (let i = 0; i < this.listData.length; i++) {
        pageContent = pageContent + '<tr>' +
          '<td>' + (this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy')) + '</td>' +
          '<td>' + this.listData[i].client_name + '</td>' +
          '<td>' + this.listData[i].brand_name + '</td>' +
          '<td>' + this.listData[i].product_name + '</td>' +
          '<td>' + this.listData[i].keyword + '</td>' +
          '<td>' + this.listData[i].product_category + '</td>' +
          '<td>' + this.listData[i].market_place + '</td>' +
          '<td>' + this.listData[i].country + '</td>' +
          '<td class="text-right">' + this.listData[i].price + '</td>' +
          '<td>' + this.listData[i].currency + '</td>' +
          '<td class="text-right">' + this.listData[i].item_number + '</td>' +
          // '<td>'+this.listData[i].url_profile+'</td>'+
          // '<td>'+this.listData[i].url+'</td>'+
          '<td>' + this.listData[i].status + '</td>' +
          '<td class="text-right">' + this.listData[i].likes + '</td>' +
          '<td>' + this.listData[i].in_stock + '</td>' +
          '<td>' + this.listData[i].type_of_infringement + '</td>' +
          '<td>' + this.listData[i].seller_id + '</td>' +
          '<td>' + this.listData[i].seller_name + '</td>' +
          '<td>' + this.listData[i].phone_number_1 + '</td>' +
          '<td>' + this.listData[i].phone_number_2 + '</td>' +
          '<td>' + this.listData[i].email_address_1 + '</td>' +
          '<td>' + this.listData[i].email_address_2 + '</td>' +
          '<td>' + this.listData[i].address + '</td>' +
          '</tr>'

      }

      pageContent = pageContent + '</tbody>' +
        '</table>' +
        '</body></html>';
      try {
        document.write(pageContent);
        document.close();
        newWindow.print();
        newWindow.close();
      }
      catch (error) {
      }
    }
    else if (this.searchQuery.source == 'market_place' || this.searchQuery.source == 'm_commerce' || this.searchQuery.source == 'e_commerce') {
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>' +
          '<html>' +
          '<head>' +
          '<meta charset="utf-8" />' +
          '<title>Market Place</title>' +
          '<style>table {border-collapse: collapse;} table, td, th { border: 1px solid black;}</style>' +
          '</head>' +
          '<body>' + '<table>' +
          '<thead>' +
          '<tr>' +
          '<th colspan="14" >' + this.clientName + '-' + this.barndName +
          '</th>' +
          '</tr>' +
          '<tr>' +
          '<th class="text-center"> Detection Date </th>' +
          '<th >Client </th>' +
          '<th >Brand </th>' +
          '<th > Product Name </th>' +
          '<th > Keyword </th>' +
          '<th > Product Category </th>' +
          '<th > Market Place </th>' +
          '<th > Country </th>' +
          '<th > Price </th>' +
          '<th > Currency </th>' +
          '<th > Item Number </th>' +
          '<th > Url </th>' +
          '<th > Status </th>' +
          '<th > Item Available(in stock) </th>' +
          '<th > Type of infringement </th>' +
          '<th > Seller Id </th>' +
          '<th > Seller Name </th>' +
          '<th > Phone Number #1 </th>' +
          '<th > Phone Number #2 </th>' +
          '<th > Email Address #1 </th>' +
          '<th > Email Address #2 </th>' +
          '<th > Address </th>' +
          '</tr>' +
          '</thead>' +
          '<tbody>';
      for (let i = 0; i < this.listData.length; i++) {
        pageContent = pageContent + '<tr>' +
          '<td>' + (this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy')) + '</td>' +
          '<td>' + this.listData[i].client_name + '</td>' +
          '<td>' + this.listData[i].brand_name + '</td>' +
          '<td>' + this.listData[i].product_name + '</td>' +
          '<td>' + this.listData[i].keyword + '</td>' +
          '<td>' + this.listData[i].product_category + '</td>' +
          '<td>' + this.listData[i].market_place + '</td>' +
          '<td>' + this.listData[i].country + '</td>' +
          '<td class="text-right">' + this.listData[i].price + '</td>' +
          '<td>' + this.listData[i].currency + '</td>' +
          '<td class="text-right">' + this.listData[i].item_number + '</td>' +
          '<td>' + this.listData[i].url + '</td>' +
          '<td>' + this.listData[i].status + '</td>' +
          '<td>' + this.listData[i].in_stock + '</td>' +
          '<td>' + this.listData[i].type_of_infringement + '</td>' +
          '<td>' + this.listData[i].seller_id + '</td>' +
          '<td>' + this.listData[i].seller_name + '</td>' +
          '<td>' + this.listData[i].phone_number_1 + '</td>' +
          '<td>' + this.listData[i].phone_number_2 + '</td>' +
          '<td>' + this.listData[i].email_address_1 + '</td>' +
          '<td>' + this.listData[i].email_address_2 + '</td>' +
          '<td>' + this.listData[i].address + '</td>' +
          '</tr>'

      }

      pageContent = pageContent + '</tbody>' +
        '</table>' +
        '</body></html>';
      try {
        document.write(pageContent);
        document.close();
        newWindow.print();
        newWindow.close();
      }
      catch (error) {
      }
    }
    else if (this.searchQuery.source == 'stand_alone') {
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>' +
          '<html>' +
          '<head>' +
          '<meta charset="utf-8" />' +
          '<title>Standalone-Website</title>' +
          '<style>table {border-collapse: collapse;} table, td, th { border: 1px solid black;}</style>' +
          '</head>' +
          '<body>' + '<table>' +
          '<thead>' +
          '<tr>' +
          '<th class="text-center">Subdomain Date </th>' +
          '<th class="text-center">Detection Date </th>' +
          '<th >Client </th>' +
          '<th >Brand </th>' +
          '<th >Language Area </th>' +
          '<th >Market </th>' +
          '<th >Search Engine </th>' +
          '<th >Subdomain </th>' +
          '<th >Host </th>' +
          '<th >Host Email </th>' +
          '<th >Country </th>' +
          '<th >Status </th>' +
          '<th >Notices Sent </th>' +
          '<th >IP Changed</th>' +
          '<th > Seller Id </th>' +
          '<th > Seller Name </th>' +
          '<th > Phone Number #1 </th>' +
          '<th > Phone Number #2 </th>' +
          '<th > Email Address #1 </th>' +
          '<th > Email Address #2 </th>' +
          '<th > Address </th>' +
          '</tr>' +
          '</thead>' +
          '<tbody>';
      for (let i = 0; i < this.listData.length; i++) {
        pageContent = pageContent + '<tr>' +
          '<td>' + this.datepipe.transform(this.listData[i].subdomain_date, 'dd/MM/yyyy') + '</td>' +
          '<td>' + this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy') + '</td>' +
          '<td>' + this.listData[i].client_name + '</td>' +
          '<td>' + this.listData[i].brand_name + '</td>' +
          '<td>' + this.listData[i].language_area + '</td>' +
          '<td>' + this.listData[i].market + '</td>' +
          '<td>' + this.listData[i].search_engine + '</td>' +
          '<td>' + this.listData[i].subdomain + '</td>' +
          '<td>' + this.listData[i].host + '</td>' +
          '<td>' + this.listData[i].host_email + '</td>' +
          '<td>' + this.listData[i].country + '</td>' +
          '<td>' + this.listData[i].status + '</td>' +
          '<td class="text-right">' + this.listData[i].notices_sent + '</td>' +
          '<td class="text-right">' + this.listData[i].ip_changed + '</td>' +
          '<td>' + this.listData[i].seller_id + '</td>' +
          '<td>' + this.listData[i].seller_name + '</td>' +
          '<td>' + this.listData[i].phone_number_1 + '</td>' +
          '<td>' + this.listData[i].phone_number_2 + '</td>' +
          '<td>' + this.listData[i].email_address_1 + '</td>' +
          '<td>' + this.listData[i].email_address_2 + '</td>' +
          '<td>' + this.listData[i].address + '</td>' +
          '</tr>'

      }

      pageContent = pageContent + '</tbody>' +
        '</table>' +
        '</body></html>';
      try {
        document.write(pageContent);
        document.close();
        newWindow.print();
        newWindow.close();
      }
      catch (error) {
      }
    } else {
      return false;
    }

  }
  openModal(template: TemplateRef<any>, data) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.screenShots = data;
  }
  openModal2(template: TemplateRef<any>, id: number) {
    setTimeout(() => { this.activeIndex = (id); }, 0);
    this.modalRef2 = this.modalService.show(template, { class: 'second' });
  }
  //To make all check box true or false
  selectAll() {
    console.log(this.selectAllFlag,"selectall");
    
    if (this.selectAllFlag == false) {
      
      this.urlToDelete = [];
      for (let e of this.listData) {
        this.urlToDelete.push(e.id)
        this.checkBox[e.id] = true;
      }
      this.selectAllFlag = true;
      this.selectFalg = true;
    }
    else {
      for (let e of this.listData) {

        this.checkBox[e.id] = false;

      }
      this.urlToDelete = [];
      this.selectAllFlag = false;
      this.selectFalg = false;
    }

  }
  //To manage check box selection
  selection(payout: any) {
    console.log(payout)
    this.test = []
    var idx = this.urlToDelete.indexOf(payout)
    if (idx > -1) {
      this.urlToDelete.splice(idx, 1)
    }
    // is newly selected
    else if (idx < 0) {
      this.urlToDelete.push(payout)
    }
    //To make select all check box true or false
    for (let e of this.listData) {
      this.test.push(e.id)
    }
    console.log(this.test.length, this.urlToDelete.length);

    if (this.test.length != this.urlToDelete.length) {
      this.selectAllFlag = false;

    } else {
      this.selectAllFlag = true;

    }
    console.log(this.urlToDelete)

  }
  delete() {
    if (this.urlToDelete.length == 0) {
      this.toastr.info('Please select the data to delete.');
      //this.toastr.error("Please select the data to delete.", "Failed");
      return false;
    }


    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete "+this.urlToDelete.length+" row(s) of selected data ?",
      buttons: ["Cancel", "Confirm"],
      dangerMode:true
    })
    .then((result) => {
      if (result) {
        let data = {
          "urlToDelete": this.urlToDelete,
          "source": this.searchQuery.source
        }
        this.loaderService.display(true);
        this.dataSearchService.deleteSearchData(data).subscribe((res: any) => {
          this.loaderService.display(false);
          if (res.status == 'success') {
            this.toastr.success("Successfully deleted", "Success");
            this.selectAllFlag = false;
            this.selectFalg = false;
            this.urlToDelete = [];
            this.checkBox = [];
            this.test = [];
            this.listData = [];
            this.getSearchData(this.searchQuery, 1);
          } else {
            this.toastr.error("Failed to delete", "Failed");
          }
        })
       
      }
    });

  }
  attachScreenshot(event: any, id: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.screenshotFile = event.target.files[0];
      console.log(this.screenshotFile)
      let data = {
        "source": this.searchQuery.source,
        "id": id
      }
      this.dataSearchService.attachScreenshot(data, this.screenshotFile).then((res) => {
        this.loaderService.display(false);
        if (res.status == "success") {
          this.screenshotFile = '';
          if (res.data_found.length != 0) {
            this.listData.forEach(element => {
              if (element.id == id) {
                element.screen_shots = res.data_found;
              }
            });
          }
          this.toastr.success(res.message, 'Success');
          console.log(res)

        }
        else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised" || res.message == "Invalid token") {
            this.toastr.error('Invalid User', 'Request Failed');
            this.authServiceObj.logout();
          } else {
            this.toastr.error('Something went wrong. Please try again', 'Failed');
          }
        }
      }, (e) => {

      });

    }
  }
  deleteScreenshot(id: any) {
    var cnf = confirm("Are you sure you want to delete the screenshot?");
    if (cnf) {
      this.loaderService.display(true);
      this.dataSearchService.deleteSreenshot(id, this.searchQuery.source).subscribe((res: any) => {

        if (res.status == 'success') {
          this.toastr.success("Screenshot deleted sucessfully", "Success");
          this.listData.forEach(element => {
            if (element.id == id) {
              element.screen_shots = null;
            }
          });
          this.loaderService.display(false);
        } else {
          this.loaderService.display(false);
          this.toastr.error("Failed to delete", "Failed");
        }
      })
    }
  }
  public onMouseDown(event) {
    this.start = event.target;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = $(this.start).parent().width();
    if (!this.indexes.includes($(this.start).parent().index() + 1)) {
      this.indexes.push($(this.start).parent().index() + 1);
    }
    this.initResizableColumns();
  }
  private initResizableColumns() {
    this.renderer.listenGlobal('body', 'mousemove', (event) => {
      if (this.pressed) {
        let width = this.startWidth + (event.x - this.startX);
        console.log(width, "widthhh")
        $(this.start).parent().css({ 'min-width': width, 'max-width': width });
        let index = $(this.start).parent().index() + 1;
        $('.glowTableBody tr td:nth-child(' + index + ')').css({ 'min-width': width, 'max-width': width });
      }
    });
    this.renderer.listenGlobal('body', 'mouseup', (event) => {
      if (this.pressed) {
        this.pressed = false;
      }
    });
  }

  removeUrls() {
    var cnf = confirm("Are you sure you want to remove urls?");
    if (cnf) {
      this.loaderService.display(true);
      let params = {
        source: this.searchQuery.source,
        ids: this.urlToDelete
      }
      this.dataSearchService.removeUrls(params).subscribe((res: any) => {

        if (res.status == 'success') {
          this.toastr.success("Urls deleted sucessfully", "Success");
          this.loaderService.display(false);
          this.listData = [];
          this.urlToDelete = [];
          this.getSearchData(this.searchQuery, 1)
        } else {
          this.loaderService.display(false);
          this.toastr.error("Failed to delete", "Failed");
        }
      })
    }
  }


}
